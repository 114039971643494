import { gql, useLazyQuery } from "@apollo/client";

import { CartContext } from "../context/CartContext";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import useGlobal from "../store";

import "../styles/all.sass";
import "../styles/custom.css";
import "../styles/custom2.css";

const Layout = ({ children }) => {
  const [globalState] = useGlobal();

  const [getCart, { data }] = useLazyQuery(CART, {
    variables: { id: globalState.cartID },
    fetchPolicy: "cache-and-network",
  });

  return (
    <>
      <CartContext.Provider value={{ data, getCart }}>
        <div id="top">
          <Navbar />
          {children}
          <Footer />
        </div>
      </CartContext.Provider>
    </>
  );
};

export default Layout;

const CART = gql`
  query Cart($id: ID!) {
    cart(id: $id) {
      id
      checkoutUrl
      createdAt
      updatedAt
      lines(first: 100) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                selectedOptions {
                  name
                  value
                }
                image {
                  id
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  id
                  title
                }
              }
            }
          }
        }
      }
      attributes {
        key
        value
      }
      estimatedCost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
    }
  }
`;
