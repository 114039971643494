import { differenceInHours } from "date-fns";
import * as ReactDOM from "react-dom/client";

import {
  initRecharge,
  loginShopifyAppProxy,
} from "@rechargeapps/storefront-client";

const SHOPIFY_STORE_ID = "bay-smokes.myshopify.com";
const RECHARGE_TOKEN =
  "strfnt_20a99e59b87398cf634d659e780af711370d5c54ec4a49426bbc1222d44468c7";

const localStorageKey = `shopify_cart_id`;
const cartTimeStamp = `shopify_cart_timestamp`;

initRecharge({
  storeIdentifier: SHOPIFY_STORE_ID,
  storefrontAccessToken: RECHARGE_TOKEN,
  loginRetryFn: async () => {
    const session = await loginShopifyAppProxy();

    return session;
  },
});

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const registerServiceWorker = () => true;

export const onClientEntry = () => {
  const cartDateTime = localStorage.getItem(cartTimeStamp);

  if (differenceInHours(Date.now(), new Date(cartDateTime)) >= 24) {
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem(cartTimeStamp);
  }
};

export { wrapRootElement, wrapPageElement } from "./gatsby-shared";
